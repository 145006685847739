import React from "react"

// Libraries
import { Link, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Components
import Carousel from "components/carousel/"

const Blog = () => {
  const { image1, image2, image3 } = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "learn/blog-image-1.png" }) {
        childImageSharp {
          gatsbyImageData(width: 279, quality: 100)
        }
      }
      image2: file(relativePath: { eq: "learn/blog-image-2.png" }) {
        childImageSharp {
          gatsbyImageData(width: 279, quality: 100)
        }
      }
      image3: file(relativePath: { eq: "learn/blog-image-3.png" }) {
        childImageSharp {
          gatsbyImageData(width: 279, quality: 100)
        }
      }
    }
  `)

  const posts = [
    {
      journal: "THE FINANCIAL DISTRICT",
      title:
        "U.S. FIRM CLAIMS ITS NUKE-POWERED DIAMOND BATTERY LASTS 28,000 YEARS",
      date: "Apr 4, 2021",
      image: image1,
      url: "https://www.thefinancialdistrict.com.ph/post/u-s-firm-claims-its-nuke-powered-diamond-battery-lasts-28-000-years",
    },
    {
      journal: "DAILYMAIL",
      title:
        "Radioactive DIAMOND battery powered by nuclear waste 'will run for 28,000 years' and could go on sale by 2023",
      date: "Mar 31, 2021",
      image: image2,
      url: "https://www.dailymail.co.uk/sciencetech/article-9422611/Battery-powered-nuclear-waste-run-28-000-years-sale-2023.html",
    },
    {
      journal: "FABCROSS",
      title: "2万8000年作動するダイヤモンド電池の—核廃物が原料",
      date: "Oct 22, 2021",
      image: image3,
      url: "https://fabcross.jp/news/2020/20201022_nano-diamond-battery.html",
    },
  ]

  const carouselConfig = {
    variableWidth: true,
    loop: true,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    dots: false,
  }

  return (
    <section id="blog" className="learn__blog section">
      <div className="container">
        <ul className="sidebar">
          <li>
            <Link to="#blog" className="active">
              Blog
            </Link>
          </li>
          <li>
            <Link to="#tokenpedia">Tokenpedia</Link>
          </li>
          <li>
            <Link to="#faq">FAQ</Link>
          </li>
          <li>
            <Link to="#resources">Resources</Link>
          </li>
        </ul>

        <div className="blog__content">
          <div className="vertical-title">
            <h1 className="highlight">News & Events</h1>
          </div>
          <div className="blog__carousel">
            <Carousel className="blog-post__carousel" {...carouselConfig}>
              {posts.map(post => (
                <a
                  className="blog-post"
                  href={post.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div>
                    <GatsbyImage
                      image={getImage(post.image)}
                      className="blog-post__image mb-3"
                      alt={post.title}
                    />

                    <div className="blog-post__content">
                      <p className="journal">{post.journal}</p>
                      <p className="font-weight--600">{post.title}</p>
                    </div>
                  </div>

                  <p className="blog-post__date">{post.date}</p>
                </a>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Blog
