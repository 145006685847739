import React from "react"

const Events = () => {
  const data = [
    {
      title: "Crypto Valley Conference | 2021",
      location:
        "Hochschule Luzern (Lucerne University of Applied Sciences and Arts) | Risch-Rotkreuz, Switzerland",
      description: <p>October 28, 2021 - October 29, 2021</p>,
      url: "https://www.cryptovalleyconference.com",
    },
    {
      title: "Democracy4All | Blockchain for Governance",
      location: "Casa Llotja de Mar | Barcelona, Spain",
      description: <p>November 11, 2021 - November 12, 2021</p>,
      url: "https://www.d4a.io",
    },
  ]

  return (
    <section className="learn__events">
      <div className="container">
        <div className="events h-100">
          {data.map(event => (
            <div className="event">
              <div className="w-100">
                <h2 className="highlight mb-2">{event.title}</h2>
                <p className="font-weight--700 mb-2">
                  Location: {event.location}
                </p>
              </div>
              <div className="w-100 align-self-end">
                {event.description}
                <a
                  href={event.url}
                  className="d-inline-block mt-5 color-hover--green"
                >
                  See details
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Events
