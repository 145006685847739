import React from "react"

// Layout
import Layout from "layouts/layout-primary"

// Sections
import Blog from "sections/learn/blog"
import Events from "sections/learn/events"
import Articles from "sections/learn/articles"
import Tokenpedia from "sections/learn/tokenpedia"
import FAQ from "sections/learn/faq"
import Resources from "sections/learn/resources"

const Learn = () => (
  <Layout>
    <Blog />
    <Events />
    <Articles />
    <Tokenpedia />
    <FAQ />
    <Resources />
  </Layout>
)

export default Learn
