import React from "react"

// Libraries
import { Link } from "gatsby"

// Components
import VerticalTitle from "components/vertical-title/"

// Icons
import Icon1 from "assets/icons/learn/icon-resources-1.inline.svg"
import Icon2 from "assets/icons/learn/icon-resources-2.inline.svg"
import Icon3 from "assets/icons/learn/icon-resources-3.inline.svg"

const Resources = () => {
  const data = [
    {
      icon: <Icon1 />,
      title: "Coming Soon",
      cta: {
        label: "White Paper",
        url: "/",
      },
    },
    {
      icon: <Icon2 />,
      title: "Coming Soon",
      cta: {
        label: "Light Paper",
        url: "/",
      },
    },
    {
      icon: <Icon3 />,
      title: "Coming Soon",
      cta: {
        label: "Media Kit",
        url: "/",
      },
    },
  ]

  return (
    <section id="resources" className="learn__resources">
      <div className="container">
        <ul className="sidebar">
          <li>
            <Link to="#blog">Blog</Link>
          </li>
          <li>
            <Link to="#tokenpedia">Tokenpedia</Link>
          </li>
          <li>
            <Link to="#faq">FAQ</Link>
          </li>
          <li>
            <Link to="#resources" className="active">
              Resources
            </Link>
          </li>
        </ul>

        <div className="h-100 resources__content">
          <VerticalTitle title="Get Resources" animated />

          <div className="row h-100">
            {data.map(item => (
              <div className="col-12 col-md-4 mb-4 mb-sm-0">
                <div className="resource__item">
                  <div className="w-100">{item.icon}</div>

                  <div className="w-100 content text-center">
                    <h2 className="mb-3">{item.title}</h2>
                    <a href={item.cta.url} className="w-100 button">
                      {item.cta.label}
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Resources
