import React from "react"

// Libraries
import { Link } from "gatsby"

// Components
import VerticalTitle from "components/vertical-title/"
import Accordion from "components/accordion/"

const FAQ = () => {
  const data = [
    {
      question: "What is the purpose of a dual token system?",
      answer: (
        <p>
          The dual Token environment is composed of the NDB and VOLT TOKENs.
          <br />
          <br />
          NDB token is a fixed supply token that will contribute to NDB's
          ecosystem, NDB City, and clean innovations.
          <br />
          <br />
          On the other hand, the VOLT TOKEN is a variable supply token serving
          as a payment method for the energy and services produced or
          commercialized by NDB.
        </p>
      ),
    },
    {
      question: "What is a VOLT TOKEN?",
      answer: (
        <p>
          At NDB, we plan to expand our activities across multiple directions.
          <br />
          <br />
          To achieve this, we have established NDB City, a cross-platform for
          innovation and business.
          <br />
          <br />
          Our technologies will be at the center of the expansion, and the Volta
          token will be used to power them.
          <br />
          <br />
          This utility token is a live payment instrument for the energy
          produced.
          <br />
          <br />
          Using our app, you will be able to unblock NDB's energy and use your
          products, almost like you are paying your utility bills.
          <br />
          <br />
          You can pay as you consume and earn tokens by selling the energy back
          to the grid.
          <br />
          <br />
          VOLT TOKEN is scheduled to deploy after the NDB token.
          <br />
          <br />
          VOLT TOKEN will be a variable supply, burnable and mintable token (NDB
          Hub), and functions to power applications using NDB products.
          <br />
          <br />
          Through this token, the users will have the possibility to lease and
          access benefits from our products.
        </p>
      ),
    },
    {
      question: "What is the NDB Token?",
      answer: (
        <p>
          The NDB token is created in the ERC-20 protocol using Ethereum
          Network; however, transfer plans to other blockchains remain possible.
          <br />
          <br />
          By holding NDB tokens, you contribute to our vision, advancing the
          development of technologies and solutions.
          <br />
          <br />
          You will be able to change, interact, and transform your NDB token in
          the open market or inside our ecosystem by staking your NDB tokens
          inside a pool.
          <br />
          <br />
          Our ecosystem consists of pools of partners and licensees.
          <br />
          <br />
          Staking your NDB tokens in a pool contributes to developing the
          owner's technologies and future products delivered in collaboration
          with NDB.
          <br />
          <br />
          By staking NDB tokens on the different pools, the stakers can earn
          dividends as VOLT TOKENs.
          <br />
          <br />
          Based on time and the availability of resources, we plan to expand our
          ecosystem described in [NDB CITY] vertically.
        </p>
      ),
    },
    {
      question: "What is Airdrop? ",
      answer: (
        <p>
          The Airdrop rewards the NDB token holders who decided to stake their
          tokens inside the pool(s).
          <br />
          <br />
          The user will be automatically rewarded according to their
          contribution percentage once the pool gets Airdrops.
        </p>
      ),
    },
    {
      question: "What are the pools? ",
      answer: (
        <p>
          In the NDB Blockchain, the NDB tokens are parked in the pool to
          receive VOLT TOKENs as a reward.
          <br />
          <br />
          The user can access pools via the NDB app.
          <br />
          <br />
          NDB blockchain has multiple pools. The pools range from NDB City
          partners or licensees who enter into a commercial relationship with
          NDB. <br />
          <br />
          A reliable operator manages each public or private stake pool of NDB
          tokens. The operator can be NDB, an individual, or a business.
          <br />
          <br />
          A stake pool is a network node with a public address that stakers can
          contribute to and receive rewards.
          <br />
          <br />
          Being part of a pool will give you access to unique private benefits
          or VOLT TOKENs for your Smart Energy subscription and products.
          <br />
          <br />
          Stake pools can be created to support projects that benefit technology
          development and the NDB token holders.
          <br />
          <br />
          The pool's participants will get a reward proportionally to their
          staked amount for each transaction.
        </p>
      ),
    },
    {
      question: "What is the relationship between both NDB and VOLT TOKENs?",
      answer: (
        <p>
          The only relationship between both tokens is the Airdrops.
          <br />
          <br />
          When you stake NDB tokens, there is the possibility of earning Volta
          tokens.
          <br />
          <br />
          The user is free to trade them in the conventional exchanges as well
          as in the NDB app.
        </p>
      ),
    },
    {
      question: "What is the NDB Hub?",
      answer: (
        <p>
          NDB Hub is responsible for maintaining and safeguarding NDB's best
          interest by managing developments, seeking feedback from the
          community, and searching for improvements when needed.
          <br />
          <br />
          This institution performs AML and KYC procedures and proposes growth
          strategies related to NDB's applications.
        </p>
      ),
    },
    {
      question: "How can I earn more NDB or VOLT TOKENs?",
      answer: (
        <p>
          First, you can buy the tokens.
          <br />
          <br />
          After the whole project has been deployed, you can earn more by
          increasing your stake of NDB Tokens inside one of the many pools.
          <br />
          <br />
          Once they are open, you can earn more VOLT TOKENs.
        </p>
      ),
    },
    {
      question:
        "Can I stake my NDB tokens without having an account on the NDB app?",
      answer: (
        <p>
          No, you can not.
          <br />
          <br />
          You must go through the KYC/AML process and accept the term of use to
          utilize all the benefits from the ecosystem.
        </p>
      ),
    },
    {
      question: "Which protocol are the tokens using?",
      answer: (
        <p>
          NDB token is using the ERC-20 protocol from the Ethereum network.
          <br />
          <br />
          By the release of NDB products, the HUB and the community will discuss
          the possibility of migration to a lower transaction fee protocol.
        </p>
      ),
    },
    {
      question: "Can I credit my product with an NDB token?",
      answer: (
        <p>
          No, you can not.
          <br />
          Once the products are released, the user will access Smart Energy
          subscriptions within NDB apps.
          <br />
          <br />
          Payment for subscriptions will be possible via the VOLT TOKEN.
          <br />
          <br />
          Upon the action of crediting, the token will burn and vanish from the
          blockchain and your wallet.
        </p>
      ),
    },
  ]

  return (
    <section id="faq" className="learn__tokenpedia faq">
      <div className="container">
        <ul className="sidebar">
          <li>
            <Link to="#blog">Blog</Link>
          </li>
          <li>
            <Link to="#tokenpedia">Tokenpedia</Link>
          </li>
          <li>
            <Link to="#faq" className="active">
              FAQ
            </Link>
          </li>
          <li>
            <Link to="#resources">Resources</Link>
          </li>
        </ul>

        <div className="tokenpedia__content h-100">
          <VerticalTitle
            title="Our token faqs"
            className="mb-4 mb-md-0"
            animated
          />

          <div className="content">
            <div>
              <h2 className="highlight">Question</h2>
              <div className="row">
                {data &&
                  data.map(question => (
                    <div className="col-12">
                      <Accordion {...question} key={question.question} />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FAQ
