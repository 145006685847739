import React, { useState } from "react"

const Articles = () => {
  const [selectedArticle, setSelectedArticle] = useState(0)

  const data = [
    {
      title: "NDB Blockchain Ecosystem",
      content: (
        <p>
          NDB aims to supply the world with an energy source that last longer
          than its application. In a world where we are still mostly dependent
          on non-renewable energy sources, NDB will tremendously diminish the
          need for such sources of energy. <br />
          <br />
          Nano Diamond Battery is an innovative energy generator and storage
          that redefines and revolutionizes the battery as we know it. Its
          longevity and performance are ensured by converting the radioactive
          decay from nuclear waste or radioisotopes into energy.
          <br />
          <br />
          Our disruptive technology requires an environment that satisfies all
          of our stakeholders while keeping incentives aligned throughout the
          entire cycle of value generation and delivery.
          <br />
          <br />
          Blockchain technology plays a key role in assisting NDB in connecting
          our customers, partners, and governments towards acquiring and
          managing the Nano Diamond Battery's energy output. <br />
          <br />
          We welcome you to learn about your role in helping NDB to launch our
          blockchain token ecosystem.
        </p>
      ),
      url: "",
    },
  ]

  return (
    <section className="learn__articles">
      <div className="container">
        <div className="articles__title triangle text-center">
          <h1>Articles</h1>
        </div>

        <div className="articles">
          {data[selectedArticle] && (
            <div className="article">
              <h2 className="mb-4">{data[selectedArticle].title}</h2>
              {data[selectedArticle].content}
            </div>
          )}
        </div>

        <div className="filters text-right">
          {data && (
            <ul>
              {data.map((article, index) => (
                <li className="mb-3" key={article.title}>
                  <button
                    type="button"
                    className={`filter ${
                      selectedArticle === index ? "active" : ""
                    }`}
                    onClick={() => setSelectedArticle(index)}
                  >
                    — Article {(index + 1).toString()}
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </section>
  )
}

export default Articles
