import React, { useState } from "react"

// Libraries
import PropTypes from "prop-types"

const Accordion = ({ question, answer }) => {
  const [active, setActive] = useState(false)

  const handleClick = () => {
    const activeAccordions = document.querySelectorAll(".accordion.active")
    activeAccordions.forEach(accordion => accordion.classList.remove("active"))

    setActive(!active)
  }

  return (
    <div className={`accordion ${active ? "active" : ""}`}>
      <button
        type="button"
        className="accordion__toggler w-100 d-flex justify-content-between font-weight--700"
        onClick={() => handleClick()}
      >
        <span>{question}</span>
        <span className="sign" />
      </button>

      <div className="accordion__content">{answer}</div>
    </div>
  )
}

Accordion.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.element.isRequired,
}

export default Accordion
