import React from "react"

// Libraries
import { Link } from "gatsby"

// Components
import Accordion from "components/accordion/"

const Tokenpedia = () => {
  const firstColumn = [
    {
      question: "What is a cryptocurrency?",
      answer: (
        <p>
          Cryptocurrency is a virtual currency secured by cryptography.
          <br />
          <br />
          Blockchain is a network system among computers.
          <br />
          <br /> The majority of the cryptocurrencies are decentralized by
          blockchain technology.
        </p>
      ),
    },
    {
      question: "Who controls the cryptocurrency network?",
      answer: (
        <p>
          The blockchain has a record-keeping system that allows
          cryptocurrencies to be decentralized.
          <br />
          <br />
          As merit, cryptocurrencies can not be controlled or manipulated by a
          centralized person or government.
          <br />
          <br />
          In a blockchain system, all transactions are verified by a network of
          thousands of computers called miners.
        </p>
      ),
    },
    {
      question: "Why cryptocurrency?",
      answer: (
        <p>
          Cryptocurrencies allow you to pay online using virtual tokens in a
          safe and decentralized manner.
          <br />
          <br />
          Cryptocurrencies, together with blockchain technology, have
          significant potential.
          <br />
          <br />
          This potential ranges from finance to voting systems.
          <br />
          <br />
          Additionally, cryptocurrencies transaction costs are much lower than
          traditional currencies.
        </p>
      ),
    },
    {
      question: "How do you receive cryptocurrency?",
      answer: (
        <p>
          To buy cryptocurrencies, you need an online wallet application.
          <br />
          <br />
          You can create an account on the application to receive and hold a
          cryptocurrency.
        </p>
      ),
    },
    {
      question: "Are cryptocurrencies real money?",
      answer: (
        <p>
          Money is a durable, divisible, portable, fungible medium of exchange.
          <br />
          <br />
          Because cryptocurrencies have these properties, they are considered
          money.
          <br />
          <br />
          Moreover, as the cryptocurrency supply is limited, like gold or
          silver, it is not fiat but real money.
        </p>
      ),
    },
    {
      question: "Who are the users of cryptocurrencies?",
      answer: (
        <p>
          The users are individuals or legal entities who submit transactions
          into the blockchain network for validation and process.
          <br />
          <br />
          The equivalent banking operation is the use of online banking to send
          money.
          <br />
          <br /> However, the main difference is the absence of centralized
          authority.
        </p>
      ),
    },
  ]

  const secondColumn = [
    {
      question: "What happens if I lose my wallet ID?",
      answer: (
        <p>
          Suppose a wallet ID or file is lost without having backups, then,
          unfortunately, the coins are lost forever.
        </p>
      ),
    },
    {
      question: "Can cryptocurrencies be hacked?",
      answer: (
        <p>
          The blockchain system protects cryptocurrencies.
          <br />
          The blockchain network is significantly extended. It is much easier
          and cost-efficient to follow the protocols than hacking the system.
        </p>
      ),
    },
    {
      question: "How does cryptocurrency make money?",
      answer: (
        <p>
          Since cryptocurrencies are growing fast, buying and holding them is a
          reasonable way of earning money—cryptocurrencies' value increases in
          the overall trend.
        </p>
      ),
    },
    {
      question: "Are cryptocurrencies legal?",
      answer: (
        <p>
          The legal status of cryptocurrencies varies from state to state. Yet,
          the majority of countries accept cryptocurrencies as a legal system.
        </p>
      ),
    },
    {
      question: "Am I supposed to pay taxes for cryptocurrencies?",
      answer: (
        <p>
          The Internal Revenue Service considers cryptocurrency to be property.
          <br />
          Therefore, taxpayers are required to report their virtual transactions
          as US dollars on their tax returns.
        </p>
      ),
    },
  ]

  return (
    <section id="tokenpedia" className="learn__tokenpedia">
      <div className="container">
        <ul className="sidebar">
          <li>
            <Link to="#blog">Blog</Link>
          </li>
          <li>
            <Link to="#tokenpedia" className="active">
              Tokenpedia
            </Link>
          </li>
          <li>
            <Link to="#faq">FAQ</Link>
          </li>
          <li>
            <Link to="#resources">Resources</Link>
          </li>
        </ul>

        <div className="tokenpedia__content h-100">
          <div className="vertical-title mb-4 mb-md-0">
            <h1 className="highlight">Basics</h1>
          </div>

          <div className="content">
            <div>
              <h2 className="highlight mb-md-4">Tokenpedia</h2>
              <div className="row">
                <div className="col-12 col-sm-6">
                  {firstColumn &&
                    firstColumn.map(question => (
                      <Accordion {...question} key={question.question} />
                    ))}
                </div>
                <div className="col-12 col-sm-6">
                  {secondColumn &&
                    secondColumn.map(question => (
                      <Accordion {...question} key={question.question} />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Tokenpedia
