import React from "react"

// Libraries
import PropTypes from "prop-types"
import Slick from "react-slick"

// Styles
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const Carousel = ({ children, ...rest }) => <Slick {...rest}>{children}</Slick>

export default Carousel

Carousel.propTypes = {
  children: PropTypes.element.isRequired,
}
